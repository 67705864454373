import React from 'react';

import MuiChip, { ChipProps } from '@mui/material/Chip';
import { lighten } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { Modify } from '../utils';

export type Props = Modify<
  ChipProps,
  {
    color?:
      | 'primary'
      | 'secondaryNavy'
      | 'secondaryGray'
      | 'success'
      | 'warning'
      | 'error';
    styleVariant?: 'fill' | 'strokeFill' | 'stroke';
  }
>;

function Chip(props: Props) {
  const {
    color = 'primary',
    styleVariant = 'fill',
    deleteIcon = <CloseIcon />,
    sx,
    ...other
  } = props;

  return (
    <MuiChip
      sx={[
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
        {
          '& .MuiChip-deleteIcon': {
            margin: '0 -6px 0 5px'
          }
        },
        /**
         * fill
         */
        styleVariant === 'fill' &&
          color === 'primary' && {
            bgcolor: 'primary02.main',
            color: 'primary02.contrastText',
            border: 1,
            borderColor: 'primary02.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'primary02.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'primary02.contrastText'
            }
          },
        styleVariant === 'fill' &&
          color === 'secondaryNavy' && {
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            border: 1,
            borderColor: 'primary.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'primary.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'primary.contrastText'
            }
          },
        styleVariant === 'fill' &&
          color === 'secondaryGray' && {
            bgcolor: 'secondary02.main',
            color: 'secondary02.contrastText',
            border: 1,
            borderColor: 'secondary02.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'secondary02.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'secondary02.contrastText'
            }
          },
        styleVariant === 'fill' &&
          color === 'success' && {
            bgcolor: 'success.main',
            color: 'success.contrastText',
            border: 1,
            borderColor: 'success.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'success.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'success.contrastText'
            }
          },
        styleVariant === 'fill' &&
          color === 'warning' && {
            bgcolor: 'warning.main',
            color: 'warning.contrastText',
            border: 1,
            borderColor: 'warning.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'warning.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'warning.contrastText'
            }
          },
        styleVariant === 'fill' &&
          color === 'error' && {
            bgcolor: 'error.main',
            color: 'error.contrastText',
            border: 1,
            borderColor: 'error.main',
            '.MuiChip-clickable&:hover, .MuiChip-clickable&:focus': {
              bgcolor: 'error.main'
            },
            '& .MuiChip-deleteIcon': {
              color: 'error.contrastText'
            }
          },
        /**
         * strokeFill
         */
        styleVariant === 'strokeFill' &&
          color === 'primary' && {
            bgcolor: (theme) => lighten(theme.palette.primary02.main, 0.9),
            color: 'primary02.main',
            border: 1,
            borderColor: 'primary02.main',
            '& .MuiChip-deleteIcon': {
              color: 'primary02.main'
            }
          },
        styleVariant === 'strokeFill' &&
          color === 'secondaryNavy' && {
            bgcolor: (theme) => lighten(theme.palette.primary.main, 0.9),
            color: 'primary.main',
            border: 1,
            borderColor: 'primary.main',
            '& .MuiChip-deleteIcon': {
              color: 'primary.main'
            }
          },
        styleVariant === 'strokeFill' &&
          color === 'secondaryGray' && {
            bgcolor: (theme) => lighten(theme.palette.secondary02.main, 0.9),
            color: 'secondary02.main',
            border: 1,
            borderColor: 'secondary02.main',
            '& .MuiChip-deleteIcon': {
              color: 'secondary02.main'
            }
          },
        styleVariant === 'strokeFill' &&
          color === 'success' && {
            bgcolor: (theme) => lighten(theme.palette.success.main, 0.9),
            color: 'success.main',
            border: 1,
            borderColor: 'success.main',
            '& .MuiChip-deleteIcon': {
              color: 'success.main'
            }
          },
        styleVariant === 'strokeFill' &&
          color === 'warning' && {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9),
            color: 'warning.contrastText',
            border: 1,
            borderColor: 'warning.main',
            '& .MuiChip-deleteIcon': {
              color: 'warning.contrastText'
            }
          },
        styleVariant === 'strokeFill' &&
          color === 'error' && {
            bgcolor: (theme) => lighten(theme.palette.error.main, 0.9),
            color: 'error.main',
            border: 1,
            borderColor: 'error.main',
            '& .MuiChip-deleteIcon': {
              color: 'error.main'
            }
          },
        /**
         * stroke
         */
        styleVariant === 'stroke' &&
          color === 'primary' && {
            bgcolor: 'transparent',
            color: 'primary02.main',
            border: 1,
            borderColor: 'primary02.main',
            '& .MuiChip-deleteIcon': {
              color: 'primary02.main'
            }
          },
        styleVariant === 'stroke' &&
          color === 'secondaryNavy' && {
            bgcolor: 'transparent',
            color: 'primary.main',
            border: 1,
            borderColor: 'primary.main',
            '& .MuiChip-deleteIcon': {
              color: 'primary.main'
            }
          },
        styleVariant === 'stroke' &&
          color === 'secondaryGray' && {
            bgcolor: 'transparent',
            color: 'secondary02.main',
            border: 1,
            borderColor: 'secondary02.main',
            '& .MuiChip-deleteIcon': {
              color: 'secondary02.main'
            }
          },
        styleVariant === 'stroke' &&
          color === 'success' && {
            bgcolor: 'transparent',
            color: 'success.main',
            border: 1,
            borderColor: 'success.main',
            '& .MuiChip-deleteIcon': {
              color: 'success.main'
            }
          },
        styleVariant === 'stroke' &&
          color === 'warning' && {
            bgcolor: 'transparent',
            color: 'warning.contrastText',
            border: 1,
            borderColor: 'warning.main',
            '& .MuiChip-deleteIcon': {
              color: 'warning.contrastText'
            }
          },
        styleVariant === 'stroke' &&
          color === 'error' && {
            bgcolor: 'transparent',
            color: 'error.main',
            border: 1,
            borderColor: 'error.main',
            '& .MuiChip-deleteIcon': {
              color: 'error.main'
            }
          }
      ]}
      deleteIcon={deleteIcon}
      {...other}
    />
  );
}

export default Chip;
