// https://github.com/gpbl/react-day-picker/blob/master/packages/react-day-picker/src/hooks/useInput/useInput.ts
import React from 'react';

import {
  format as formatDate,
  parse,
  isValid,
  isBefore,
  isAfter,
  startOfDay,
  startOfMonth
} from 'date-fns';
import { enUS } from 'date-fns/locale';

import {
  DayClickEventHandler,
  DayPickerProps,
  InputDayPickerProps,
  InputHTMLAttributes,
  MonthChangeEventHandler,
  UseInputOptions,
  UseInput
} from 'react-day-picker';

function parseFromToProps(
  props: Pick<
    DayPickerProps,
    'fromYear' | 'toYear' | 'fromDate' | 'toDate' | 'fromMonth' | 'toMonth'
  >
): { fromDate: Date | undefined; toDate: Date | undefined } {
  const { fromYear, toYear, fromMonth, toMonth } = props;
  let { fromDate, toDate } = props;

  if (fromMonth) {
    fromDate = startOfMonth(fromMonth);
  } else if (fromYear) {
    fromDate = new Date(fromYear, 0, 1);
  }
  if (toMonth) {
    toDate = startOfMonth(toMonth);
  } else if (toYear) {
    toDate = new Date(toYear, 11, 31);
  }

  return {
    fromDate: fromDate ? startOfDay(fromDate) : undefined,
    toDate: toDate ? startOfDay(toDate) : undefined
  };
}

const DefaultFormat = 'P';

export interface ControlledInputOptions extends UseInputOptions {
  onSelect?: (day: Date) => void;
}

/** Return props for binding an input field to DayPicker. */
export function useDateInput(options: ControlledInputOptions = {}): UseInput {
  const {
    locale = enUS,
    required,
    format = DefaultFormat,
    defaultSelected,
    today = new Date()
  } = options;
  const { fromDate, toDate } = parseFromToProps(options);

  // Shortcut to the DateFns functions
  const parseValue = (value: string) => parse(value, format, today, { locale });

  // Initialize states
  const [month, setMonth] = React.useState(defaultSelected ?? today);
  const [selectedDay, setSelectedDay] = React.useState<Date | undefined>(
    defaultSelected
  );
  const defaultInputValue = defaultSelected
    ? formatDate(defaultSelected, format, { locale })
    : '';
  const [inputValue, setInputValue] = React.useState(defaultInputValue);

  const reset = () => {
    setSelectedDay(defaultSelected);
    setMonth(defaultSelected ?? today);
    setInputValue(defaultInputValue ?? '');
  };

  const setSelected = (date: Date | undefined) => {
    setSelectedDay(date);
    setMonth(date ?? today);
    setInputValue(date ? formatDate(date, format, { locale }) : '');
  };

  const handleDayClick: DayClickEventHandler = (day, { selected }) => {
    if (!required && selected) {
      setSelectedDay(undefined);
      setInputValue('');
      return;
    }
    setSelectedDay(day);
    setInputValue(day ? formatDate(day, format, { locale }) : '');
    if (options.onSelect) options.onSelect(day);
  };

  const handleMonthChange: MonthChangeEventHandler = (month) => {
    setMonth(month);
  };

  // When changing the input field, save its value in state and check if the
  // string is a valid date. If it is a valid day, set it as selected and update
  // the calendar’s month.
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
    const day = parseValue(e.target.value);
    const isBeforeFrom = fromDate ? isBefore(day, fromDate) : true;
    const isAfterTo = toDate ? isAfter(day, toDate) : true;
    if (!isValid(day) || isBeforeFrom || isAfterTo) {
      setSelectedDay(undefined);
      return;
    }
    setSelectedDay(day);
    setMonth(day);
    if (options.onSelect) options?.onSelect(day);
  };

  // Special case for _required_ fields: on blur, if the value of the input is not
  // a valid date, reset the calendar and the input value.
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const day = parseValue(e.target.value);
    if (!isValid(day)) {
      reset();
    }
  };

  // When focusing, make sure DayPicker visualizes the month of the date in the
  // input field.
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      reset();
      return;
    }
    const day = parseValue(e.target.value);
    if (isValid(day)) {
      setMonth(day);
    }
  };

  const dayPickerProps: InputDayPickerProps = {
    mode: 'custom',
    month: month,
    onDayClick: handleDayClick,
    onMonthChange: handleMonthChange,
    selected: selectedDay,
    locale,
    fromDate: options?.fromDate,
    toDate: options?.toDate,
    today
  };

  const inputProps: InputHTMLAttributes = {
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    value: inputValue
  };

  return { dayPickerProps, inputProps, reset, setSelected };
}
