import React, { Fragment } from 'react';

import { Box } from '@mui/material';
import { DayContent, DayContentProps } from 'react-day-picker';

function DayPickerDay(props: DayContentProps) {
  return (
    <Fragment>
      <DayContent {...props} />
      <Box className="backdrop">{props.date.getDate()}</Box>
    </Fragment>
  );
}

export default DayPickerDay;
